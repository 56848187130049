<template>
	<div class="main">
		<el-card class="card_box">
			<div
				slot="header"
				class="card_title"
				@click="$router.push({ path: '/user/adduser' })"
			>
				<i class="el-icon-arrow-left"></i>
				<span>用户管理/用户列表/详情</span>
			</div>
			<div class="add_main">
				<span class="font_weight_bold">账号信息</span>
				<div class="margin_top_20">
					<el-form
						label-position="right"
						label-width="120px"
						:model="formData"
						:rules="rule"
						ref="formData"
					>
						<el-form-item label="头像" prop="avatar_img">
							<img
								style="width: 200px"
								v-if="formData.avatar_img"
								:src="formData.avatar_img"
								@click="uploadPicture(formData.avatar_img)"
							/>
							<div v-else>
								<div
									class="el-upload__text"
									@click="uploadPicture(formData.pic)"
								>
									<img
										style="width: 100px"
										src="../../../assets/images/uploadImg.png"
										alt=""
									/>
									<br /><em>点击加号上传</em></div
								>
							</div>
							<!-- 剪裁组件弹窗 -->
							<el-dialog
								title="裁切封面"
								:visible.sync="cropperModel"
								width="950px"
								center
								:append-to-body="true"
							>
								<cropper-image
									:Name="cropperName"
									@uploadImgSuccess="handleUploadSuccess"
									ref="child"
								>
								</cropper-image>
							</el-dialog>
						</el-form-item>
						<el-form-item label="账号名称" prop="nickname">
							<el-input
								v-model="formData.nickname"
								:disabled="$route.query.type == 'detail'"
							></el-input>
						</el-form-item>

						<el-form-item label="用户密码" prop="password">
							<el-input v-model="formData.passwordNew"> </el-input>
						</el-form-item>
						<el-form-item label="性别" prop="sex">
							<el-select
								v-model="formData.sex"
								placeholder="请选择性别"
								:disabled="$route.query.type == 'detail'"
							>
								<el-option label="男" value="1"> </el-option>
								<el-option label="女" value="2"> </el-option>
								<el-option label="保密" value="3"> </el-option>
							</el-select>
						</el-form-item>

						<el-form-item label="职位" prop="position">
							<el-input
								v-model="formData.position"
								:disabled="$route.query.type == 'detail'"
							></el-input>
						</el-form-item>
						<el-form-item label="介绍" prop="desc">
							<el-input
								v-model="formData.desc"
								:disabled="$route.query.type == 'detail'"
							></el-input>
						</el-form-item>
						<el-form-item label="微信" prop="wechat">
							<el-input
								v-model="formData.wechat"
								:disabled="$route.query.type == 'detail'"
							></el-input>
						</el-form-item>
						<el-form-item label="邮箱" prop="email">
							<el-input
								v-model="formData.email"
								:disabled="$route.query.type == 'detail'"
							></el-input>
						</el-form-item>
						<el-form-item label="手机号" prop="mobile">
							<el-input
								v-model="formData.mobile"
								:disabled="$route.query.type == 'detail'"
							></el-input>
						</el-form-item>
						<el-form-item label="twitter" prop="twitter">
							<el-input
								v-model="formData.twitter"
								:disabled="$route.query.type == 'detail'"
							></el-input>
						</el-form-item>

						<el-form-item label="项目名称" prop="userinfo.project_name">
							<el-input
								v-model="formData.userinfo.project_name"
								:disabled="$route.query.type == 'detail'"
							></el-input>
						</el-form-item>
						<el-form-item label="项目LOGO">
							<el-upload
								class="upload-demo"
								action=""
								:show-file-list="false"
								:disabled="$route.query.type == 'detail'"
								:on-success="handleAvatarSuccess"
								:before-upload="beforeAvatarUpload"
								:http-request="uploadImageavatarlogo"
							>
								<img
									v-if="formData.userinfo.project_logo"
									:src="formData.userinfo.project_logo"
									class="logo"
								/>
								<i v-else class="el-icon-plus avatar-uploader-icon"></i>
							</el-upload>
						</el-form-item>
						<el-form-item label="项目联系人">
							<el-input
								v-model="formData.userinfo.project_contacts"
								:disabled="$route.query.type == 'detail'"
							></el-input>
						</el-form-item>
						<el-form-item label="项目联系电话">
							<el-input
								v-model="formData.userinfo.project_phone"
								:disabled="$route.query.type == 'detail'"
							></el-input>
						</el-form-item>
						<el-form-item label="项目网址">
							<el-input
								v-model="formData.userinfo.project_url"
								:disabled="$route.query.type == 'detail'"
							></el-input>
						</el-form-item>
						<el-form-item label="社交媒体">
							<el-input
								v-model="formData.userinfo.social_media"
								:disabled="$route.query.type == 'detail'"
							></el-input>
						</el-form-item>
						<el-form-item label="审计报告">
							<el-input
								v-model="formData.userinfo.audit_report"
								:disabled="$route.query.type == 'detail'"
							></el-input>
						</el-form-item>
						<el-form-item label="github地址">
							<el-input
								v-model="formData.userinfo.github_url"
								:disabled="$route.query.type == 'detail'"
							></el-input>
						</el-form-item>
						<el-form-item label="账号状态" prop="state">
							<el-select
								v-model="formData.state"
								placeholder="请选择账号状态"
								:disabled="$route.query.type == 'detail'"
							>
								<el-option label="正常" value="1"> </el-option>
								<el-option label="禁用" value="2"> </el-option>
							</el-select>
						</el-form-item>

						<el-form-item>
							<div class="flex justify_content_c align_items_c">
								<el-button
									type="primary"
									class="custom_btn_width_100"
									@click="submit('formData')"
									v-if="$route.query.type != 'detail'"
									>保存</el-button
								>
								<el-button class="custom_btn_width_100" @click="$router.back()"
									>返回</el-button
								>
							</div>
						</el-form-item>
					</el-form>
				</div>
			</div>
		</el-card>
	</div>
</template>

<script>
import { validateNull, validateEMail, validatePhone } from "@/utils/validate";
import {
	uploadImage,
	getUsersdetails,
	createusers,
	updateusers,
} from "@/api/user";
export default {
	name: "",
	data() {
		return {
			rule: {
				avatar_img: [
					{ validator: validateNull, trigger: "change", required: true },
				],
				nickname: [
					{ validator: validateNull, trigger: "blur", required: true },
				],
				sex: [{ validator: validateNull, trigger: "change", required: true }],

				mobile: [
					{ validator: validateNull, trigger: "blur", required: true },
					{ validator: validatePhone, trigger: "blur" },
				],

				"userinfo.project_logo": [
					{ validator: validateNull, trigger: "blur", required: true },
				],
				"userinfo.project_url": [
					{ validator: validateNull, trigger: "blur", required: true },
				],
				"userinfo.social_media": [
					{ validator: validateNull, trigger: "blur", required: true },
				],
				state: [{ validator: validateNull, trigger: "change", required: true }],
			},
			value: "",
			formData: {
				state: "",
				nickname: "",
				mobile: "",
				avatar_img: "",
				sex: "",
				desc: "",
				position: "",
				email: "",
				wechat: "",
				twitter: "",
				document_type: "",

				project_type: "",
				userinfo: {
					auth_type: "3",
					company_name: "",
					company_desc: "",
					company_no: "",
					company_img1: "",
					company_img2: "",
					project_logo: "",
					truename: "",
					id_card: "",
					id_card_img1: "",
					id_card_img2: "",
					project_name: "",
					project_contacts: "",
					project_phone: "",
					project_url: "",
					social_media: "",
					audit_report: "",
					github_url: "",
				},
			},
			//参数
			cropperModel: false,
			cropperName: "",
		};
	},
	created() {
		if (!this.$route.query.id) {
			this.clearFormAdd();
		} else {
			this.getUsersdetails();
		}
	},
	methods: {
		//封面设置
		uploadPicture(name) {
			if (this.$route.query.type == "detail") {
				// 详情不支持编辑图片
			} else {
				this.cropperName = name;
				this.cropperModel = true;
			}
		},
		//图片上传成功后
		handleUploadSuccess(data) {
			console.log(data.url);
			this.cropperModel = false;
			this.formData.avatar_img = data.url;
		},
		// 获取用户详情
		getUsersdetails() {
			const that = this;
			getUsersdetails({ d: that.$route.query.id })
				.then((res) => {
					if (res.code == 200) {
						that.formData = res.data;
						that.formData.sex = that.formData.sex.toString();
						that.formData.state = that.formData.state.toString();
						that.formData.userinfo.auth_type =
							that.formData.userinfo.auth_type.toString();
					} else {
						that.$message.error(res.message);
					}
				})
				.catch((error) => {
					that.$message.error(error.message);
				});
		},
		// 新增
		submit(formName) {
			this.$refs[formName].validate((valid) => {
				if (valid) {
					const that = this;
					let form = {};
					if (
						this.formData.passwordNew != null &&
						this.formData.passwordNew.length > 0
					) {
						this.formData.password = this.formData.passwordNew;
					} else {
						delete this.formData.password;
					}
					this.formData.userinfo.auth_type = "3";
					for (let key in this.formData) {
						if (key === "userinfo") {
							for (let key2 in this.formData.userinfo) {
								form[key2] = this.formData.userinfo[key2];
							}
						} else {
							form[key] = this.formData[key];
						}
					}
					if (this.$route.query.type == "edit") {
						updateusers(form)
							.then((res) => {
								if (res.code == 200) {
									that.$router.push({ path: "/user/userlist" });
								}
							})
							.catch((error) => {});
					} else {
						createusers(form)
							.then((res) => {
								if (res.code == 200) {
									that.$router.push({ path: "/user/userlist" });
								}
							})
							.catch((error) => {});
					}
				} else {
					console.log("error submit!!");
					return false;
				}
			});
		},
		// 上传图片
		uploadImageavatar(obj) {
			const file = obj.file;
			let formdata = new FormData();
			formdata.append("file", file);
			const that = this;
			uploadImage(formdata)
				.then(function (res) {
					if (res.code == 200) {
						console.log(res.data.url);
						that.formData.avatar_img = res.data.url;
					} else {
						that.$message.error(res.message);
					}
				})
				.catch(function (error) {});
		},
		uploadImageavatarlogo(obj) {
			const file = obj.file;
			let formdata = new FormData();
			formdata.append("file", file);
			const that = this;
			uploadImage(formdata)
				.then(function (res) {
					if (res.code == 200) {
						console.log(res.data.url);
						that.formData.userinfo.project_logo = res.data.url;
					} else {
						that.$message.error(res.message);
					}
				})
				.catch(function (error) {});
		},
		clearFormAdd() {
			for (let key in this.formData) {
				if (key === "userinfo") {
					for (let key2 in this.formData.userinfo) {
						this.formData.userinfo[key2] = "";
					}
				} else {
					this.formData[key] = "";
				}
			}
			console.log(this.formData);
		},
	},
};
</script>

<style scoped>
.main {
	width: calc(100% - 40px);
}

.add_main {
	width: 70%;
	margin: 0 auto;
}
</style>
